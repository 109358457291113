import CareerResources from '@talentinc/gatsby-theme-ecom/components/CareerResources/CareerResources';
import CareerSpecialist from '@talentinc/gatsby-theme-ecom/components/CareerSpecialist/CareerSpecialist';
import HomeFAQ from '@talentinc/gatsby-theme-ecom/components/HomeFAQ/HomeFAQ';
import HomeHero from '@talentinc/gatsby-theme-ecom/components/HomeHero/HomeHero';
import HomePress from '@talentinc/gatsby-theme-ecom/components/HomePress/HomePress';
import LearnMore from '@talentinc/gatsby-theme-ecom/components/LearnMore/LearnMore';
import NewFeatures from '@talentinc/gatsby-theme-ecom/components/NewFeatures/NewFeatures';
import FeaturedIn from '@talentinc/gatsby-theme-ecom/components/FeaturedIn/FeaturedIn';
import ReviewSection from '@talentinc/gatsby-theme-ecom/components/Reviews/ReviewSection';
import RoundBackgroundBlock from '@talentinc/gatsby-theme-ecom/components/RoundBackgroundBlock/RoundBackgroundBlock';
import TestimonialsV2 from '@talentinc/gatsby-theme-ecom/components/TestimonialsV2/TestimonialsV2';
import { RBBVariants, ReviewComponentVariants } from '@talentinc/gatsby-theme-ecom/types/landingPageV2';
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import { graphql } from 'gatsby';
import React from 'react';
import { PageContextType } from 'types/page';
import pageData from '../../data/home.json';
import LandingPageV3Template, { Section } from '../../templates/landingPageV3';
import FooterCTA from '@talentinc/gatsby-theme-ecom/components/FooterCTA/FooterCTA'

interface Props {
    pageContext: PageContextType
}

export default function Home(props: Props) {
    const {} = props

    return (
        <LandingPageV3Template 
            pageData={pageData} 
            pageContext={props.pageContext}
        >
            <Section>
                <HomeHero/>
            </Section>
            <Section slideOnMobile noVerticalPadding>
                <FeaturedIn/>
            </Section>
            <Section>
                <NewFeatures />
            </Section>
            <Section innerPadded>
                <LearnMore/>
            </Section>
            <Section>
                {/* @TODO: Refactor Trust pilot block to not rely on Contentful */}
                <ReviewSection 
                    reviewSection={{ 
                        customReviewComponent: ReviewComponentVariants.ProgressBarCarousel,
                        body: { 
                            raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Reviewed by the community. Trusted by professionals.","nodeType":"text"}],"nodeType":"heading-2"},{"data":{"target":{"sys":{"id":"5VPTxAFigVzMq7fMgrg7UG","type":"Link","linkType":"Entry"}}},"content":[],"nodeType":"embedded-entry-block"},{"data":{},"content":[{"data":{},"marks":[],"value":"","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
                            references: [{
                                brand: 'Default Site Brand',
                                contentful_id: "5VPTxAFigVzMq7fMgrg7UG",
                                variant: 'FiveStars Descriptive',
                                __typename: "ContentfulTrustPilotRatingWidget"
                            }]
                        }
                    }} />
            </Section>
            <Section innerPadded>
                <TestimonialsV2/>
            </Section>
            <Section innerPadded>
                <CareerSpecialist/>
            </Section>
            <Section>
                <RoundBackgroundBlock 
                    variant={RBBVariants.HomeResumeExamples}
                    content={{ raw: '' }}
                    imageUrl={'https://images.ctfassets.net/7thvzrs93dvf/2ml5GB2CJsl1yl1XzeFAfh/29e8dc414056ce03d0461bbd6d3c074f/homeresumeexamples.png'}
                    imageUrlMobile="https://images.ctfassets.net/7thvzrs93dvf/2ml5GB2CJsl1yl1XzeFAfh/5b035aa1aa3b2632c191b34f0b0b69ae/homeresumeexamplesmobile.png"
                    bottom={null}
                    sectionClass={null}
                    codeClasses={null}
                >
                </RoundBackgroundBlock>
            </Section>
            <Section innerPadded>
                <HomeFAQ/>
            </Section>
            <Section innerPadded>
                <CareerResources/>
            </Section>
            <Section innerPadded>
                <HomePress></HomePress>
            </Section>
            <FooterCTA />
            <Footer useSimulatedPageLoad={false} />
        </LandingPageV3Template>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }`
